
















import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import CreativeForm from './components/CreativeForm.vue'

@Component({
  components: {
    Widget,
    CreativeForm,
  },
})
export default class CreativeEdit extends Vue {
  @Prop()
  public id!: string;

  public title: string = 'Create Creative';

  public created() {
    if (this.id) {
      this.title = 'Edit Creative'
    }
  }
}
