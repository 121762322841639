






















































































































































import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Creative from '@/models/Creative'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import { VastMediaFile } from 'vast-client'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
  },
})
export default class CreativeHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public creative: Creative = new Creative()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public modal: any = {
    preview: false,
    tag: false,
  }

  public media_file: VastMediaFile | null = null

  public query: string[] = []

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public fields: any = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
    },
    {
      key: 'advertiser_id',
      label: 'Advertiser',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'duration',
      label: 'Duration',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'ready',
      label: 'Status',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ]

  public query_settings: any = {
    company_fields: [
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'type:vast',
        value: 'type:vast',
      },
      {
        name: 'type:video',
        value: 'type:video',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public creatives(context: any) {
    this.loading = true
    return Creative.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public createCreative() {
    this.$router.push('/app/creative')
  }

  public confirmTag(creative: Creative) {
    this.creative = creative
    this.modal.tag = true
  }

  public generateTAG() {
    this.creative.updateTag()
  }

  public previewCreative(creative: Creative) {
    this.creative = creative
    this.media_file = null
    this.creative.preview().then((r: VastMediaFile) => {
      if (r) {
        this.media_file = r
        this.modal.preview = true
      }
    })
  }

  public editCreative(id: string) {
    this.$router.push(`/app/creative/${id}`)
  }

  public created() {
    this.records = this.creatives.length
  }
}
